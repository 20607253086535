import React from 'react';
import './ComingSoon.css';
import logo from '../assets/comingsoon.jpg'; // 

function ComingSoon() {
  return (
    <div className="coming-soon">
      <img src={logo} alt="comingsoon" />
    </div>
  );
}

export default ComingSoon;
